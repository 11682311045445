import { productService } from '@/api';
import { Query, Paging } from '@/api/base';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableOption, OsTableColumn } from '@/components/os-table/os-table';
import { ChargeUnitEnum, ResourceStatusEnum } from '@/resource/enum';
import { ProductList, ProductResource } from '@/resource/model';
import {
  getStatusClass,
  getStatusI18Key,
  translation,
  messageError,
  getResourceStatusOptions
} from '@/utils';
import { ImportFile } from '@/views/dialogs';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    OsTable,
    OsTableQuery,
    OsPagination,
    OsTableOperation,
    ImportFile
  }
})
export default class Product extends Vue {
  /**
   * 表格配置
   */
  public tableOption: OsTableOption<ProductResource> = {
    loading: false,
    data: [],
    fit: true,
    size: 'mini',
    defaultSort: { prop: 'createTime', order: 'descending' }
  };
  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<ProductResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'productCode',
      label: 'product.productCode',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'categoryName',
      label: 'product.categoryName',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        const data: ProductList = row as ProductList;
        if(data.secondCategoryName) {
          return `${data.categoryName} / ${data.secondCategoryName}`;
        }

        return `${data.categoryName}`;
      }
    },
    {
      prop: 'productName',
      label: 'product.productName',
      minWidth: '400px',
      showOverflowTooltip: true
    },
    {
      prop: 'price',
      label: 'product.price',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        const product = row as ProductResource;

        return `${product.price} ${translation('common.yuan')} / ${translation(
          'chargeUnit.' + ChargeUnitEnum[product.priceUnit]
        )}`;
      }
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      minWidth: '200px',
      showOverflowTooltip: true
    }
  ];
  /**
   * 查询表单数据对象
   */
  public queryForm: Query<{
    keywords: string;
    categoryId: number | null;
    status: ResourceStatusEnum | null;
  }> = {
    keywords: '',
    categoryId: null,
    status: null
  };
  /**
   * 表格查询表单配置
   */
  public queryFormOptions: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Cascader',
      field: 'categoryId',
      label: 'product.categoryName',
      option: {
        placeholder: 'product.selectCategoryName',
        filterable: true,
        clearable: true,
        collapseTags: true,
        showAllLevels: false,
        props: {
          checkStrictly: true,
          emitPath: false
        }
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: getResourceStatusOptions
    }
  ];

  /**
   * 分页配置
   */
  public pagintaionOption: Paging = {
    currentPage: 1,
    showCount: 10
  };
  /**
   * 表格总记录数
   */
  public totalData = 0;
  /**
   * dialog显示状态
   */
  public dialogVisible = false;
  /**
   * 编辑数据对象
   */
  public editRow: ProductResource | null = null;
  /**
   * 导入dialog显示状态控制
   */
  public importDialogVisible = false;
  /**
   * 导入dialog 标题
   */
  public importTitle = 'product.import';
  /**
   * 表格选中数据
   */
  private selectedRows: Array<ProductResource> = [];
  /**
   * 查询按钮点击事件
   */
  public handleQueryClick(): void {
    this.loadData();
  }
  /**
   * 分页事件
   */
  public handlePagination(): void {
    this.loadData();
  }
  /**
   * 表格行选中事件
   */
  public handleSelectionChange(rows: Array<ProductResource>): void {
    this.selectedRows = rows;
  }
  /**
   * 加载表格数据
   */
  public loadData(): void {
    this.tableOption.loading = true;
    productService
      .getList(this.queryForm as ProductResource, this.pagintaionOption)
      .then((res: { data: ProductResource[]; total: number }) => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
  /**
   * 重新加载
   */
  public reload(): void {
    this.pagintaionOption.currentPage = 1;
    (this.$refs.productTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }
  public getStatusClass(status: number): string {
    return getStatusClass(status);
  }
  public getStatusI18Key(status: number): string {
    return getStatusI18Key(status);
  }
  /**
   * vue创建完成钩子
   */
  public created(): void {
    this.loadMaterialCategories();
    this.loadData();
  }

  /**
   * 获取材料分类
   */
  private async loadMaterialCategories(): Promise<void> {
    const categoryList = await productService.getAllUsingClassify();

    const queryOptionItem = this.queryFormOptions.find(option => option.field === 'categoryId');

    if (queryOptionItem) {
      queryOptionItem.optionData = productService.handleCascaderOption(categoryList);
    }
  }
}
